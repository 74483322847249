import React from 'react'
import Slide from "react-reveal/Zoom";

export default function HeaderSubTitle({color,text,subTitle,inverse}) {
    return (
        <div className={inverse?" cover-red":"white cover-white "} style={{ height: "220px" }}>
      <Slide right  cascade>
        <div style={{height:"12vh"}}></div>
        <div className="section-title-container-extra">
        <div style={{display:"flex",flexDirection:"column"}}>
          <h2 className="section-title upper" style={inverse?{color: 'white'}:{color: 'black'}}>{text} {" "}</h2>
           </div>
          <div className="underline-menu" style={{ width: 40,backgroundColor:color }}></div>
          <h2 className="section-title " style={inverse?{color: 'grey'}:{color: 'grey'}}>{subTitle? " "+" "+subTitle:""}</h2>
        
        </div>
       
      </Slide>
    </div>
    )
}
