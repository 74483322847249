import React from "react";

export default function Mapa() {
  return (
    <div className="col-12 col-lg-6 row g-0">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d26290.28906023619!2d-58.46883295226573!3d-34.546310050343855!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcb5cdc2b862fb%3A0x8537280f94151b1!2s3%20de%20Febrero%201869%2C%20Buenos%20Aires!5e0!3m2!1sen!2sar!4v1633241803313!5m2!1sen!2sar"
        width="100%"
        height={"100%"}
        style={{ border: 0 }}
        allowFullScreen="true"
        loading="lazy"
      />
    </div>
  );
}
