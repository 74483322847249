import React,{useRef,useEffect} from "react";
import ActDescription from "../components/ActDescription";
import HomeBody from "../components/HomeBody";
import CoverLogo from "../components/sliders/CoverLogo";
import Slide from "react-reveal/Slide";
import { useLocation } from 'react-router-dom'
export default function Home() {

  const location = useLocation();
  const myRef = useRef(null)

  useEffect(() => {
    console.log(location.pathname);
    if(location.pathname=="/hacemos"){
      myRef.current.scrollIntoView({ behavior: 'smooth',block:"start"  })
    }

  }, []);
  return (
    <div className="routeContainer">
      <CoverLogo></CoverLogo>
     
      <Slide right>
        <ActDescription></ActDescription>
      </Slide>
   
      <div ref={myRef} style={{position: "relative",top: "-80px", left: 0}}></div>
        <HomeBody ></HomeBody>
    </div>
  );
}


