import { Button } from "@material-ui/core";
import React from "react";
import TextInputMultipleLine from "../inputs/TextInputMultipleLine";
import TextInputOneLine from "../inputs/TextInputOneLine";
import { toast } from "react-toastify";
import HeadShake from "react-reveal/Fade";
import { Link } from "react-router-dom";
import Delay from "./../delay/delay";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";
import { useForm, ValidationError } from "@formspree/react";

const FormSection = () => {
  const [state, handleSubmit] = useForm("xpzbvzpy");

  const { executeRecaptcha } = useGoogleReCaptcha();

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = React.useCallback(async () => {
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }

    const token =  executeRecaptcha("actionExample");
    // console.log("TOKENNN");
    // console.log(token);
    // Do whatever you want with the token
  }, []);

  // You can use useEffect to trigger the verification as soon as the component being loaded
  React.useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);
  return (
    <div
      style={{ minHeight: "80vh", backgroundColor: "#E1903D" }}
      className="col-12 col-lg-6 row g-0"
    >
      <div className="col-1 col-lg-2"></div>

      <div className="col-10 col-lg-8">
        <form
          id="myForm"
          name="myform"
          onSubmit={(e) => {
       
            // handleReCaptchaVerify();
          
            toast.success("Mensaje Enviado", {
              position: "top-right",

              autoClose: 8000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              closeButton: false,
            });

                 //e.preventDefault();
                 handleSubmit(e);
          }}
        >
          <TextInputOneLine label="NOMBRE"></TextInputOneLine>
          <TextInputOneLine label="MAIL"></TextInputOneLine>
          <TextInputMultipleLine label="MENSAJE"></TextInputMultipleLine>

          <div className="vermas-container">
            <Delay d={300} height={34}>
              <HeadShake>
                <button
                  style={{ marginTop: 20 }}
                  className="vermas-button"
                  type="submit"
                  /* onClick={() => {
                   toast.success("Mensaje Enviado", {
                     position: "bottom-right",
 
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                     closeButton: false
                   });
                 }}*/
                //  onClick={handleReCaptchaVerify}
                >
                  Enviar
                </button>
              </HeadShake>
            </Delay>
          </div>
        </form>
        {/*      <button
          className="explore button-enviar"
          onClick={() => {
            toast.success("Mensaje Enviado", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              closeButton: false
            });
          }}
        >
          
          <span className="icon-right after" />
        </button>*/}
      </div>
    </div>
  );
};

export default FormSection;
