import React, { useEffect, useState } from "react";
import Slide from "react-reveal/Zoom";
import f from './../assets/staff/JuanFaerman-Creatividad.jpg';
import HeaderSubTitle from "../components/HeaderBackground";
export default function Agencia() {
  const [Pessoas, setPessoas] = useState([]);
  const [Loading, setLoading] = useState(true);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async id => {
    await fetch(
      "https://raw.githubusercontent.com/ferbsferbs/static-infinitedev/main/people.json"
    )
      .then(res => {
        return res.json();
      })
      .then(result => {
        let list=[]
        for (const key in result) {
          if (result.hasOwnProperty.call(result, key)) {
            var element = result[key];
            element.id=key
            list.push(element);
          }
        }
          setPessoas(list);
          setLoading(false);

    
      });
  };
  return (
    <div className="routeContainer " style={{ }}>
      <ImageHeader></ImageHeader>
      <FormSection  data={Pessoas}></FormSection>
    </div>
  );
}

const FormSection = ({data}) => {
  return (
    <>
      <div className="cover-white-no-resize" style={{ minHeight: "100vh", backgroundColor: "#fff" }}>
        <h2
          className="section-title-no-space pt-1 mb-5 pb-2"
          style={{ color: "black" }}
        >
        
        </h2>
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex"
          }}
        >
          <div
            className="row"
            style={{
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              display: "flex"
            }}
          >
            {data.map(user => (
              <RenderUser user={user}></RenderUser>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

const RenderUser = ({ user }) => {
  return (
    <div className="col-12 col-sm-6 col-md-4 col-lg-4 p-0 p-sm-2">
      <div
        className="card-per draw-border-yellow"
        onClick={()=>{window.open(user.linkedin,"_blank")}}
        style={{
          width: "100%",
          backgroundImage: "url("+user.image+")",
          cursor:"pointer",
          "backgroundRepeat": "no-repeat",
          "backgroundSize": "cover",
          height: 450,
        justifyContent: "center",display:"flex",alignItems:"flex-end"
        }}
      >
 
        <div className="mb-3" style={{zIndex: 200}}>
          <h3 className="section-title-no-space"style={{fontSize:21,color:"white",opacity:1}}>{user.name}</h3>
          <p className="section-title-no-space"style={{zIndex:999,fontSize:17,fontStyle:"italic",color:"white",opacity:1}}>{user.cargo}</p>
        </div>
      </div>
    </div>
  );
};
const ImageHeader = () => {
  return (
        <HeaderSubTitle text={"AGENCIA"}  inverse color={"#F7D97C"}></HeaderSubTitle>
  );
};
