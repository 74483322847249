import React from "react";
import HeadShake from "react-reveal/Fade";
import { Link } from "react-router-dom";
import Delay from "../components/delay/delay";
function ActDescription() {
  return (
    <div className="gray cover-gray " >
      <div className="presentation-text">
        <p>
          <strong> Enero es mucho más que el primer mes del año.</strong> Es el nacimiento de grandes promesas. Es la puesta
          en marcha de muchos proyectos y cuando se renuevan las energías. En enero algunos cambian el aire
          y otros se quedan para disfrutar esa tranquilidad tan disfrutable. En cualquier caso, todos coinciden en
          que es un escenario ideal. Por eso en enero todos estamos de buen humor. Con ganas de hacer y crecer.
          De cambiar para mejor. De hacer cosas nuevas. Por eso, enero es mucho más que el primer mes del año.
          <strong> Por eso, todo empieza en enero.</strong>


        </p>
      </div>
      <div className="presentation-text-mobile">
        <p>
          <strong> Enero es mucho más que el primer mes del año.</strong> Es el nacimiento de grandes promesas. Es la puesta en marcha de proyectos y cuando se renuevan las energías. Por eso en enero todos estamos de buen humor y con ganas de hacer cosas nuevas. <strong>Por eso, todo empieza en enero.</strong>

        </p>
      </div>
      {/*<div className="vermas-container">
        <Delay d={1800} height={34}>
          <HeadShake>
            <Link
              to="/agencia"
              className="vermas-button"

            >
              Ver más
            </Link>
          </HeadShake>
        </Delay>
  </div>
      </div>*/}
    </div>
  );
}

export default ActDescription;
