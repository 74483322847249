import React, { useEffect, useState } from "react";
import Slide from "react-reveal/Slide";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { imagesAgencia, imagesAgenciaMobile } from "./../imagesArray";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import ChevronRight from "@material-ui/icons/ChevronRight";
import { TodosProjetos } from "./../onlineMedia";

function HomeBody() {
  const [Projetos, setProjetos] = useState([]);
  const [Loading, setLoading] = useState(true);
  const [openCreatividad, setOpenCreatividad] = useState([false, false, false]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async id => {
    let list = [];
    for (const key in TodosProjetos) {
      if (TodosProjetos.hasOwnProperty.call(TodosProjetos, key)) {
        var element = TodosProjetos[key];
        element.id = key;
        list.push(element);
      }
    }
    setProjetos(list);
    setLoading(false);
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoPlay: true
  };

  const handleOpenCreatividad = n => {
    let openCreatividadAux = [...openCreatividad];
    openCreatividadAux[n] = !openCreatividadAux[n];
    for (let i = 0; i < openCreatividadAux.length; i++) {
      if (i != n) {
        openCreatividadAux[i] = false;
      }
    }
    setOpenCreatividad(openCreatividadAux);
  };

  return (
    <div className="white cover-white " style={{ height: "auto" }}>
      <Slide bottom cascade>
        <div
          style={{ marginTop: "20px" }}
          className="hacemos-cards-container p-2"
        >
          <div className="hacemos-midle-card draw-border  hoverFX">
            <div>
              <h2 className="hacemos-card-title  ">CREATIVIDAD</h2>
              <div className="purple-bar "></div>
              <div className="hacemos-card-text">
                Campañas de comunicación 360°
                <br />
                Estrategias creativas
                <br />
                Desarrollo de contenidos
              </div>
            </div>
          </div>
          <div className="hacemos-midle-card  draw-border  hoverFX">
            <h2 className="hacemos-card-title">ESTRATEGIA</h2>
            <div className="purple-bar"></div>
            <div className="hacemos-card-text">
              Planificación estratégica de medios
              <br />
              Contratación y monitoreo
              <br />
              Social media
            </div>
          </div>
          <div className="hacemos-midle-card  draw-border  hoverFX">
            <h2 className="hacemos-card-title">PRODUCCIÓN Y MEDIA</h2>
            <div className="purple-bar"></div>
            <div className="hacemos-card-text">
              Realización integral en todo tipo de formatos
              <br />
              Shopper
              <br />
              Activaciones
            </div>
          </div>
        </div>
      </Slide>


      <div
        style={{
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          display: "flex",
          marginTop: "20px",
          marginBottom: "20px"
        }}
      >
        {!Loading && (
          <ImagesProyectosCarrousel data={Projetos}></ImagesProyectosCarrousel>
        )}
      </div>

      <div
        style={{
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          display: "flex",
          marginBottom: "20px"
        }}
      >
        <LaAgencia></LaAgencia>
      </div>
    </div>
  );
}

export default HomeBody;

const LaAgencia = () => {
  const settings = {
    autoplay: true,
    pauseOnHover: false,
    autoplayspeed: 800
  };
  return (
    <div style={{ width: "100%" }}>
      <Slider className="imagesAgencia" {...settings}>
        {imagesAgencia.map(e => {
          return <AgenciaCard text={e}></AgenciaCard>;
        })}
      </Slider>
      {/*   <Slider className="imagesAgenciaMobile" {...settings}>
        {imagesAgenciaMobile.map(e => {
          return <AgenciaCard text={e}></AgenciaCard>;
        })}
      </Slider>*/}
    </div>
  );
};

const AgenciaCard = ({ text }) => {
  return (
    <div className="col-12 col-md-12 col-lg-12 p-0" style={{ width: "100%" }}>
      <div
        style={{
          opacity: 1,

          backgroundPosition: "center",
          height: 650,
          justifyContent: "center",
          display: "flex",
          borderRadius: 0,
          alignItems: "center",
          width: "100%",
          backgroundSize: "cover"
        }}
      >
        {<img src={text} width={"100%"}></img>}
      </div>
    </div>
  );
};

const SliderMobileProyecto = ({ data }) => {

  return (

    <div style={{ marginTop: 40 }}>
      {data.map(e => {
        return <ProyectoCardMobile projeto={e}></ProyectoCardMobile>;
      })}
    </div>

  );
};

const ProyectoCardMobile = ({ projeto }) => {
  if (!projeto) return null;
  return (
    <div style={{ width: "85%", margin: "auto", marginBottom: 5, marginTop: 5 }} className="   cardProyectoMobile">
      <Link
        className="card-per-reverse draw-border-blue"
        to={projeto.id == "medife" ? "/medife" : "/proyectoSingle/" + projeto.id}
        style={{
          width: "100%",

          backgroundImage: "url(" + projeto.bannerImage + ")",
          
          "background-repeat": "no-repeat",
          "background-size": "cover",

          justifyContent: "flex-start",
          display: "flex",
          borderRadius: 0,
          alignItems: "flex-start",
          height: "220px"
        }}
      >
        {/* <img src="assets/images/CAPA.jpg" width={"100%"}></img> */}

        <div
          style={{
            backgroundImage: "linear-gradient(to bottom, rgba(255,255,255,0), rgba(0,0,0,0.9))",
            height:"50%",
            alignSelf:"flex-end",
            backgroundPosition:"bottom",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            display: "flex",
            flexDirection: "column",
            width: "100%",
            paddingLeft: 20,
            paddingTop: 20
          }}
        >

          <div
            className="blue-project-bar section-title-no-space"
            style={{ zIndex: 9999, marginLeft: "10%" }}
          ></div>

          <h3 className="project-mobile-card-title" style={{ zIndex: 500 }}>
           <strong> {projeto.title}</strong> - {projeto.client}
          </h3>

        </div>
      </Link>
    </div>
  );
};

const ImagesProyectosCarrousel = ({ data }) => {
  return (
    <div className=" w-100">
      <div className="sliderProyectosWeb">
        <SliderWeb data={data}></SliderWeb>
      </div>
      <SliderMobileProyecto data={data}></SliderMobileProyecto>
    </div>
  );
};

const SliderWeb = ({ data }) => {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    pauseOnHover: false,
    autoplayspeed: 1000
  };
  return (
    <Slider {...settings}>
      {data.map((e, i) => {
        return i % 2 == 0 ? (
          <>
            <ProyectoCardFix projeto={data[i]}></ProyectoCardFix>
            <ProyectoCardFix projeto={data[i + 1]}></ProyectoCardFix>
          </>
        ) : (
          ""
        );
      })}
    </Slider>
  );
};

const ProyectoCardFix = ({ projeto }) => {
  if (!projeto) return null;
  return (
    <div className="col-12 col-sm-6 col-md-4 col-lg-12 p-0  css-sm-bugfix">
      <Link
        className="card-per-reverse draw-border-blue"
        to={projeto.id == "medife" ? "/medife" : "/proyectoSingle/" + projeto.id}
        style={{
          width: "100%",

          backgroundImage: "url(" + projeto.previewCardImage + ")",

          "background-repeat": "no-repeat",
          "background-size": "cover",
          height: "25vw",
          justifyContent: "flex-start",
          display: "flex",
          borderRadius: 0,
          alignItems: "flex-start"
        }}
      >
        {/* <img src="assets/images/CAPA.jpg" width={"100%"}></img> */}

        <div
          className="bar-fad"
          style={{
            justifyContent: "flex-start",
            alignItems: "flex-start",
            display: "flex",
            flexDirection: "column",
            width: "100%"
          }}
        >
          <h3
            className="section-title-no-space hacemos-card-title"
            style={{ zIndex: 9999, textAlign: "left" }}
          >
            {projeto.title}
          </h3>
          <div
            className="blue-project-bar section-title-no-space"
            style={{
              zIndex: 9999,
              marginLeft: "10%",
              marginTop: "2%",
              marginBottom: "5%"
            }}
          ></div>


        </div>
      </Link>
    </div>
  );
};
