import { styled, TextField } from '@material-ui/core';
import React from 'react'
const CssTextField = styled(TextField)({

    '& label.Mui-focused': {
      color: 'white',fontSize:20,
    },
    "& .MuiInput-underline": {
      borderBottomColor: "white",
    },
    "& .Mui-focused": {
      borderBottomColor: "white",
      fontWeight:"bolder",
      "font-size":"20px !important",
      "letter-spacing":"2px !important",
    },
    "& .MuiInput-underline:hover:before": {
      borderBottomColor: "white",
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "white",
    },
    "& .MuiInput-underline:focus": {
      borderBottomColor: "white",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "white",
    },

    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white",
        
        color: "white"
        
      },
      "&:hover fieldset": {
        borderColor: "white",
      },
    
    }
  });
export default function TextInputOneLine({label}) {
    return (
        <div className="mt-5">

        <CssTextField
          inputProps={{
            style: { fontFamily: "Oswald", color: "white",fontSize:30 }
          }}
          InputLabelProps={{
            style: { fontFamily: "Oswald", color: "white",fontSize:20,fontWeight: "bold"}
          }}
       
          fullWidth
          id="name"
        name="name"
          label={label}
          variant="standard"
        />
        </div>
    )
}
