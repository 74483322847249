export const imagesAgencia=[
"https://i.imgur.com/mo4yEzE.jpg",
"https://i.imgur.com/MmJRJMs.jpg",
"https://i.imgur.com/zlZ36iL.jpg",
"https://i.imgur.com/ijKluyJ.jpg",
"https://i.imgur.com/Zcauub4.jpg",
"https://i.imgur.com/YaSWVMl.jpg",
"https://i.imgur.com/7Hj0LhT.jpg",
"https://i.imgur.com/nTvLv1g.jpg",
"https://i.imgur.com/ZQ04THm.jpg",
"https://i.imgur.com/fgvLOXW.jpg",
"https://i.imgur.com/yVznQTf.jpg",


]

export const imagesAgenciaMobile=[
  "https://i.ibb.co/3m87pXr/DSC5913.jpg",
  "https://i.ibb.co/6gd0Gn9/DSC5925.jpg",
  "https://i.ibb.co/Kxj7wQv/DSC5937.jpg",
  "https://i.ibb.co/pjKd1Ph/DSC5953.jpg",
  "https://i.ibb.co/k8TDvfB/DSC5990.jpg",
  "https://i.ibb.co/F08rhWg/DSC6000.jpg",
  "https://i.ibb.co/RYPZ4kG/DSC6010.jpg",
    ]