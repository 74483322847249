import { styled, TextField } from "@material-ui/core";
import React from "react";
import HeaderSubTitle from "../components/HeaderBackground";
import HomeBody from "../components/HomeBody";

import FormSection from "../components/pageContacto/form";
import Mapa from "../components/pageContacto/mapa";

export default function Contacto() {
  return (
    <div className="routeContainer" style={{ backgroundColor: "white" }}>
      <HeaderSubTitle text={"contacto"}  color={"#E1903D"}></HeaderSubTitle>
      <div className="row g-0">
     <FormSection></FormSection>
     <Mapa></Mapa>
     </div>
    </div>
  );
}
