import "./App.css";
import MainNav from "./components/MainNav";
import MainNavMobile from "./components/MainNavMobile";
import Footer from "./components/Footer";
import { BrowserRouter as Router } from "react-router-dom";
import SwitchPages from "./pages/SwitchPages";
import ScrollToTop from "./components/ScrollToTop";

import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

function App() {
  return (
    <div className="App">
      <Router>
        <ScrollToTop></ScrollToTop>
        <MainNav></MainNav>
        <MainNavMobile></MainNavMobile>

        <SwitchPages></SwitchPages>

        <Footer></Footer>
      </Router>

      <ToastContainer />
    </div>
  );
}

export default App;
