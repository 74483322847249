import React, { useEffect, useState } from "react";
import Fade from "react-reveal/Fade";
import HeaderSubTitle from "../components/HeaderBackground";
import Slide from "react-reveal/Slide";


export default function Hacemos() {



  return (
    <div className="routeContainer">
      <HeaderSubTitle text={"HACEMOS"} color={"#E097B9"}></HeaderSubTitle>
      <Fade>
        <div className={" cover-pink "} style={{ padding: 20 }}>
          <Slide bottom cascade>
            <div


            >
              <div className="hacemos-m-card ">
                <div>
                  <h2 className="hacemos-m-card-title  ">CREATIVIDAD</h2>
                  <div className="purple-bar-m "></div>
                  <div className="hacemos-m-card-text">
                    Campañas de comunicación 360°
                    <br />
                    Estrategias creativas
                    <br />
                    Desarrollo de contenidos
                  </div>
                </div>
              </div>
              <div className="hacemos-m-card  draw-border  hoverFX">
                <h2 className="hacemos-m-card-title ">ESTRATEGIA</h2>
                <div className="purple-bar-m"></div>
                <div className="hacemos-m-card-text">
                  Planificación estratégica de medios
                  <br />
                  Contratación y monitoreo
                  <br />
                  Social media
                </div>
              </div>
              <div className="hacemos-m-card  draw-border  hoverFX">
                <h2 className="hacemos-m-card-title">DIGITAL</h2>
                <div className="purple-bar-m"></div>
                <div className="hacemos-m-card-text">
                  Diseño y programación de websites
                  <br />
                  Apps mobile
                  <br />
                  SEO, Métricas y GoogleAds
                  <br />
                  UX/UI
                </div>
              </div>

              <div className="hacemos-m-card  draw-border  hoverFX">
                <h2 className="hacemos-m-card-title">PRODUCCIÓN Y MEDIA</h2>
                <div className="purple-bar-m"></div>
                <div className="hacemos-m-card-text">
                  Realización integral
                  <br />
                  Shopper
                  <br />
                  Activaciones
                </div>
              </div>
            </div>



          </Slide>
        </div>
      </Fade>
    </div>
  );
}

const hacemosCard = () => {


}

