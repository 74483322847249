import { styled, TextField } from '@material-ui/core';
import React from 'react'
const CssTextField = styled(TextField)({
  
    '& label.Mui-focused': {
      color: 'white',fontSize:20,
    },
    "& .MuiInput-underline": {
      borderBottomColor: "white",
    },
    "& .Mui-focused": {
      borderBottomColor: "white",
      fontWeight:"bolder",
      "font-size":"20px !important",
      "letter-spacing":"2px !important",
    },
    "& .MuiFilledInput-underline:hover:before": {
      borderBottomColor: "white",
    },
    "&.MuiFilledInput-underline:after":{
      "border-bottom-color": "white"

    },
    "& .MuiFilledInput-underline:before": {
      borderBottomColor: "white",
    },
    "& .MuiFilledInput-underline:focus": {
      borderBottomColor: "white",
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: "white",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white",
        
        color: "white"
        
      },
      "&:hover fieldset": {
        borderColor: "white",
      },
    
    }
  });
export default function TextInputMultipleLine({label}) {
    return (
        <div className="mt-5">
  <CssTextField
            multiline
            rows={5}
            inputProps={{
              style: { fontFamily: "Oswald", color: "white",fontSize:15 ,paddingTop:"5px",borderColor:"white",borderBottomWidth:2 }
            }}
            InputLabelProps={{
              style: { fontFamily: "Oswald", color: "white",fontSize:20,fontWeight: "bold"}
            }}
         
            fullWidth
            id="message"
            name="message"

            label={label}
            variant="filled"
          />
        </div>
    )
}
