export const TodosProjetos = {
  medife: {
    title: `Medifé y la Pandemia`,
    subTItle: " un gran desafío también desde lo creativo.",
    text: `2020 fue un año que nos puso a prueba en todo sentido. Y en nuestro caso también lo fue desde lo creativo, porque tuvimos la enorme responsabilidad de generar una serie de comunicaciones para una empresa de salud durante la pandemia.
`,
    text1: `Fue así que, a través de la consolidación de una serie de espacios de trabajo y reflexión junto al cliente, pudimos producir las campañas “Números”, “Vamos a salir” y “Brindis”: una serie de mensajes de los cuales estamos profundamente orgullosos por el impacto positivo que generaron en la sociedad, que se manifestó en una gran repercusión por parte de la gente.    `,
    textMobile: `2020 fue un año que nos puso a prueba también desde lo creativo, porque tuvimos la enorme responsabilidad de comunicar para una empresa de salud.
    
    Así, con mucho trabajo y reflexión junto al cliente, produjimos “Números”, “Vamos a salir” y “Brindis”, de los cuales estamos orgullosos por su gran impacto y repercusión.`,
    client: "Medifé",
    ano: "Año 2020",
    campanha: `Medifé pandemia`,
    marquer: ` GRÁFICA - RADIO - SPOTS - CAMPAÑA 360 - CREATIVIDAD -`,
    bannerImage: "https://i.imgur.com/9lXPESD.jpg",
    previewCardImage:"https://i.imgur.com/ALV0YSf.jpg",
    firstGallery: [
      "https://i.ibb.co/h9r99JJ/refugio-M-2.jpg",
      "https://i.ibb.co/hK26Rvw/ciudadela-1.jpg",
      "https://i.ibb.co/9nsXvkf/vp-brindar-salud-03.jpg",
      "https://i.ibb.co/GJhHQ8q/vp-brindar-salud-01.jpg",
      "https://i.ibb.co/BtM729P/vp-brindar-salud-02.jpg",
      "https://i.ibb.co/hW5SzQ2/hoja-59.jpg",
      "https://i.ibb.co/bd5bVNg/hoja-57.jpg",
      "https://i.ibb.co/8x3hN4q/cerro.jpg",
      "https://i.ibb.co/CBv4qVN/vp-brindar-salud-04.jpg",
      "https://i.ibb.co/FhRZ53Z/ciudadela-2.jpg",
      "https://i.ibb.co/q7KL00f/neuquen-puente.jpg",
      "https://i.ibb.co/J7h1gSJ/hoja-61.jpg",
      "https://i.ibb.co/TgKbznS/hoja-53.jpg",
      "https://i.ibb.co/CKHZ5z2/rosario-corrientes.jpg",
      "https://i.ibb.co/dJM3LHZ/hoja-55.jpg",
      "https://i.ibb.co/P9NZpV3/an-elo.jpg",
      "https://i.ibb.co/hyc7DWP/lomas.jpg",
      "https://i.ibb.co/HBm31fs/rosario-roca.jpg",
      "https://i.ibb.co/J7h1gSJ/hoja-61.jpg",
      "https://i.ibb.co/tKSPLK3/estado.jpg",
      "https://i.ibb.co/ykzZm6v/hoyts.jpg",
      "https://i.ibb.co/2hKWqYx/neuqen-argentina.jpg",
      "https://i.ibb.co/n7DKmqc/temperley.jpg",
      "https://i.ibb.co/QPfjwLy/costanera-sur.jpg",
      "https://i.ibb.co/8K463ZZ/led-mdq.jpg",
      "https://i.ibb.co/jrR22H1/plaza.jpg",
      "https://i.ibb.co/wzSZTTM/pavon-2.jpg",
      "https://i.ibb.co/thfLGCq/refugio-M-1.jpg",
      "https://i.ibb.co/zhXcP1y/hoja-26.jpg",
      "https://i.ibb.co/WByNpdR/pavon-1.jpg",
      "https://i.ibb.co/G09JGLN/adrogue.jpg"
    ],

    playList: [
      {
        previewImage: "",
        id:"H_SIvMDRuxs",

        youtubeUrl: (
          <iframe
            width="1584"
            height="620"
            src="https://www.youtube.com/embed/H_SIvMDRuxs"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        )
      }
    ],
    secondGallery: [],
    graficasCarrousel: [],
    graficasCarrouselBottom: [],
    radio: [
      "https://drive.google.com/file/d/14NczzrG6LF-IG8mW4LBQMp1M4WN4qjaK/view?usp=sharing"
    ],
    itemData: []
  },
  AseInstitucional: {
    title: `ASE Institucional `,
    subTItle: "",
    text: `Basándonos en la trayectoria que tiene ASE como líder en su rubro, buscamos transmitirlo tanto estética como emocionalmente desde su esencia, a través de una identificación desde el logo y la paleta de colores. Así logramos destacar los conceptos que representan su liderazgo concreto: pioneros y especialistas con más de 40 años brindando atención de máxima calidad.
`,
    textMobile: `Basándonos en la trayectoria que tiene ASE como líder en su rubro, buscamos transmitirlo tanto estética como emocionalmente desde su esencia, a través de una identificación desde el logo y la paleta de colores. Así logramos destacar los conceptos que representan su liderazgo concreto: pioneros y especialistas con más de 40 años brindando atención de máxima calidad.`,
    client: "ASE",
    ano: "Año 2019",
    campanha: `ASE Institucional`,
    marquer: ` GRÁFICA - SPOT - CREATIVIDAD -`,
    bannerImage: "https://i.imgur.com/AoOiEDT.jpg",
previewCardImage:"https://i.imgur.com/9jU50oC.jpg",
    firstGallery: [],

    playList: [
      {
        previewImage: "https://i.imgur.com/xFtyfdY.png",
        id:"w4XY5-iq7nA",
        youtubeUrl: (
          <iframe
            width="1584"
            height="620"
            src="https://www.youtube.com/embed/w4XY5-iq7nA"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        )
      }
    ],
    secondGallery: [],
    graficasCarrousel: [],
    graficasCarrouselBottom: [],
    radio: [],
    itemData: []
  },

  CamDoctor: {
    title: `Campaña Cam Doctor`,
    subTItle: "“Mente y cuerpo” ",
    text: `El principal beneficio de la telemedicina no es la comodidad, sino restar preocupaciones de una forma más ágil y con gran eficiencia. Y esa fue el concepto que transmitimos en esta campaña de Cam Doctor: el servicio de Telemedicina de Medifé, que se destaca por su versatilidad y velocidad de respuesta aún en pandemia. 
    `,

    textMobile: `El principal beneficio de la telemedicina no es la comodidad, sino restar preocupaciones de una forma más ágil y con gran eficiencia. Y esa fue el concepto que transmitimos en esta campaña de Cam Doctor: el servicio de Telemedicina de Medifé, que se destaca por su versatilidad y velocidad de respuesta aún en pandemia.     `,
    client: "Medifé",
    ano: "Junio 2021",
    campanha: `Cam Doctor`,
    marquer: ` GRÁFICA - RADIO - SPOTS - CAMPAÑA 360 - CREATIVIDAD -`,
    bannerImage: "https://i.imgur.com/SjanpG1.jpg",
previewCardImage:"https://i.imgur.com/vDi3aG0.jpg",
    firstGallery: [],

    playList: [
      {
        previewImage: "https://i.imgur.com/2PZdsMm.png",
        id:"2Xr3LsH439M",
        youtubeUrl: (
          <iframe
            width="1584"
            height="620"
            src="https://www.youtube.com/embed/2Xr3LsH439M"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        )
      }
    ],
    secondGallery: [],
    graficasCarrousel: [],
    graficasCarrouselBottom: [],
    radio: [
      "https://drive.google.com/file/d/1hppqO0uOi424w8Djt2Z9srTgBzLziROh/view?usp=sharing"
    ],
    itemData: []
  },

  bancoImagenes: {
    title: "Producción de fotos 2021",
    subTItle: "- Medifé",
    text: `Más que como medicina privada, nuestro objetivo es mostrar a Medifé como una empresa de salud. Y eso también es ver la belleza de lo que nos rodea cotidianamente. Entonces, la estética en esta campaña cumple un rol especial. Por eso realizamos dos jornadas de producción de fotos para conjugar con excelencia creatividad, dirección de arte y concepto.`,
    textMobile: `Más que como medicina privada, nuestro objetivo es mostrar a Medifé como una empresa de salud. Y eso también es ver la belleza de lo que nos rodea cotidianamente. Entonces, la estética en esta campaña cumple un rol especial. Por eso realizamos dos jornadas de producción de fotos para conjugar con excelencia creatividad, dirección de arte y concepto.`,
    client: "Medifé",
    ano: "Año 2021",
    campanha: "Producción de fotos 2021",
    marquer: ` FOTOGRAFÍA - PRODUCCIÓN - DIRECCIÓN DE ARTE - CREATIVIDAD -`,
    bannerImage: "https://i.imgur.com/ez4GywT.jpg",
previewCardImage:"https://i.imgur.com/nretorY.jpg",
    firstGallery: [
      "https://i.ibb.co/dDXSK4n/76A6258.jpg",
      "https://i.ibb.co/D4b1DZ6/76A6666.jpg",
      "https://i.ibb.co/G5htZzD/4I0A1128.jpg",
      "https://i.ibb.co/c8kmsw9/76A6636.jpg",
      "https://i.ibb.co/SPFNjLX/4I0A3161.jpg",
      "https://i.ibb.co/qJNQnVG/4I0A1784.jpg",
      "https://i.ibb.co/ZgzhjTD/4I0A1022.jpg",
      "https://i.ibb.co/C2h7j5m/4I0A3641.jpg",
      "https://i.ibb.co/dg7Y5dF/4I0A3810.jpg",
      "https://i.ibb.co/GsKjz3B/76A5854.jpg",
      "https://i.ibb.co/CHdw6Gz/76A5595.jpg",
      "https://i.ibb.co/Kw2CzxS/4I0A0916.jpg",
      "https://i.ibb.co/DWvvV3s/76A6175.jpg",
      "https://i.ibb.co/stvdHTX/76A6273.jpg",
      "https://i.ibb.co/sRfgMFF/76A6298.jpg",
      "https://i.ibb.co/nCttH3S/76A5271.jpg",
      "https://i.ibb.co/brxX5XQ/76A5617.jpg",
      "https://i.ibb.co/mSJJ7m4/76A5160.jpg",
      "https://i.ibb.co/K9J1Vfd/76A5538.jpg",
      "https://i.ibb.co/qrCdxm9/76A5865.jpg",
      "https://i.ibb.co/gTcqPPq/76A4963.jpg",
      "https://i.ibb.co/51TqTBN/76A5566.jpg",
      "https://i.ibb.co/yFvtfBS/hoja-10.jpg",
      "https://i.ibb.co/j6zTNrP/76A5618.jpg",
      "https://i.ibb.co/rbT5dMd/76A5904.jpg",
      "https://i.ibb.co/X8smndK/76A5650.jpg",
      "https://i.ibb.co/64CnqcT/76A5260.jpg",
      "https://i.ibb.co/9s90LqM/hoja-03.jpg",
      "https://i.ibb.co/ZMb5HSN/76A4941.jpg",
      "https://i.ibb.co/7pW5P4r/76A4792.jpg",
      "https://i.ibb.co/BcBpWp2/hoja-16.jpg",
      "https://i.ibb.co/b6ddm8x/hoja-05.jpg",
      "https://i.ibb.co/C2zwsSw/hoja-19.jpg",
      "https://i.ibb.co/f91bV96/76A5442.jpg",
      "https://i.ibb.co/BBQpn5m/hoja-04.jpg",
      "https://i.ibb.co/3fnq13F/76A5229.jpg",
      "https://i.ibb.co/LNTw6Fq/76A4880.jpg",
      "https://i.ibb.co/gM6WxRh/hoja-07.jpg",
      "https://i.ibb.co/WHfDTw1/hoja-06.jpg",
      "https://i.ibb.co/t8qH9VR/76A5240.jpg",
      "https://i.ibb.co/nf4z6C3/hoja-09.jpg",
      "https://i.ibb.co/k8wSqVV/hoja-14.jpg",
      "https://i.ibb.co/V9yDm8z/hoja-12.jpg",
      "https://i.ibb.co/m4JsbN4/hoja-17.jpg",
      "https://i.ibb.co/kG9pBDC/hoja-08.jpg"
    ],

    playList: [],
    secondGallery: [],
    graficasCarrousel: [],
    graficasCarrouselBottom: [],
    radio: [],
    itemData: []
  },
  cancerDeMama: {
    title: `Día Mundial del Cáncer de Mama`,
    subTItle: "",
    text: `La mayoría de las muertes por cáncer de mama pueden evitarse con prevención. Ese fue nuestro objetivo a comunicar a través de esta campaña pensada en el marco del Mes de la Lucha contra el Cáncer de Mama, cuyo fin fue concientizar con un alto impacto.
    `,

    textMobile: `La mayoría de las muertes por cáncer de mama pueden evitarse con prevención. Ese fue nuestro objetivo a comunicar a través de esta campaña pensada en el marco del Mes de la Lucha contra el Cáncer de Mama, cuyo fin fue concientizar con un alto impacto.`,
    client: "Medifé",
    ano: "Año 2019",
    campanha: `Día Mundial del Cáncer de Mama`,
    marquer: ` GRÁFICA - RADIO - SPOT - CAMPAÑA 360 - CREATIVIDAD -`,
    bannerImage: "https://i.imgur.com/bRKD1eX.jpg",
    previewCardImage:"https://i.imgur.com/zfaUPSp.jpg",
    firstGallery: [],

    playList: [
      {
        previewImage: "https://i.imgur.com/mSNVWRG.png",
        id:"oLz3MHRkM1k",
        youtubeUrl: (
          <iframe
            width="1584"
            height="620"
            src="https://www.youtube.com/embed/oLz3MHRkM1k"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        )
      }
    ],
    secondGallery: [],
    graficasCarrousel: [],
    graficasCarrouselBottom: [],
    radio: [
     
    ],
    itemData: []
  },

  diezanosmedife: {
    title: "10 años Fundación Medifé",
    subTItle: "Medifé 2019",
    text: `Tomando como punto de partida la gráfica de campaña, propusimos el concepto de abrir puertas como metáfora para transmitir los diferentes hitos y alianzas que la Fundación transitó durante sus 10 años, representados por imágenes de las puertas de sus edificios a través de un tratamiento fotográfico especial.`,
    textMobile: `Tomando como punto de partida la gráfica de campaña, propusimos el concepto de abrir puertas como metáfora para transmitir los diferentes hitos y alianzas que la Fundación transitó durante sus 10 años, representados por imágenes de las puertas de sus edificios a través de un tratamiento fotográfico especial.`,
    client: "Fundación Medifé",
    ano: "Año 2021",
    campanha: `10 años Fundación Medifé`,
    marquer: ` GRÁFICA - DIRECCIÓN DE ARTE - CREATIVIDAD -`,
    bannerImage: "https://i.imgur.com/4PHR7va.jpg",
    previewCardImage:"https://i.imgur.com/FXyE2Cj.jpg",
    secondGallery: ["https://i.ibb.co/F6j6NX3/libros.jpg",
    "https://i.ibb.co/fdcJMdD/malba-2.jpg" ,
    "https://i.ibb.co/9csDWy3/UBA-economicas.jpg",
    "https://i.ibb.co/NWqyL0Z/dona-vida.jpg",
    "https://i.ibb.co/wcsXRg9/Campamento-de-dibujo-2.jpg",
    "https://i.ibb.co/sgJLDK5/Markus.jpg",
    "https://i.ibb.co/HtT2v2t/finochietto.jpg",
    "https://i.ibb.co/WVx5LLZ/bellas-artes.jpg",
    "https://i.ibb.co/FxyZDvL/Fassin.jpg",
    "https://i.ibb.co/LrfP99y/Sennett-1.jpg",
    "https://i.ibb.co/sH0DvhX/museo-macro-2.png,"],

    playList: [],
    firstGallery: [
      "https://i.ibb.co/5B7PkXr/USARCOMOPREVIEW-Posteo2.jpg",
      "https://i.ibb.co/ZMZFyMQ/Posteo1.jpg",
      "https://i.ibb.co/thXn4mw/Story.jpg",
      "https://i.ibb.co/cFSgYGR/Posteo3.jpg",
      "https://i.ibb.co/8BzBLXX/Posteo4.jpg",
      "https://i.ibb.co/fS7ghBs/Posteo5.jpg",
      "https://i.ibb.co/fxwP0jd/Posteo6.jpg",
      "https://i.ibb.co/Nyg8NVb/Story2.jpg" ,
      "https://i.ibb.co/fHcwwnw/AipSFDm0.jpg",

      "https://i.imgur.com/1imPCh6.gif",
      "https://i.imgur.com/2fCogVR.jpg",
      "https://i.imgur.com/dFW2sDR.jpg",
      "https://i.imgur.com/rgXWRbt.jpg",
      "https://i.imgur.com/w9UnDxP.jpg",
      "https://i.imgur.com/sSKVzJB.jpg"
    ],
    graficasCarrousel: [],
    graficasCarrouselBottom: [],
    radio: [],
    itemData: []
  },
  estaConmigo: {
    title: "Está Conmigo",
    subTItle: "Medifé 2019",
    text: `En 2019 trabajamos en el desarrollo conceptual e incorporación de un claim pertinente para la marca. A partir de “Está conmigo” se
    construyeron las historias que componen esta campaña.Todas reúnen una serie de valores fundamentales para trasmitir la esencia de
    Medifé en la coyuntura 2019: cercanía, cobertura, empatía, y una presencia inteligente.A partir de este concepto y prestando plena
    atención al valor estético, ganamos síntesis e impacto, a partir de un un mensaje claro y contundente.`,
    textMobile: `En 2019 trabajamos en el desarrollo conceptual e incorporación de un claim
pertinente para la marca. A partir de “Está conmigo” se construyeron las historias
que componen esta campaña. A partir de un un mensaje claro y contundente,
prestando plena atención al valor estético, ganamos síntesis e impacto.`,
    client: "Medifé",
    ano: "Año 2019",
    campanha: `Está Conmigo`,
    marquer: ` GRÁFICA - RADIO - SPOT - CAMPAÑA 360 - CREATIVIDAD -`,
    bannerImage: "https://i.imgur.com/xA7x9LP.jpg",
    previewCardImage:"https://i.imgur.com/4GXQHiZ.jpg",
    firstGallery: [
      "https://i.imgur.com/0gmDfgg.jpg",
      "https://i.imgur.com/STKyCc4.gif",
     
      "https://i.imgur.com/Szx7ZHa.jpg",

  "https://i.imgur.com/3oPgFvU.gif",

      "https://i.imgur.com/Flq1Pjo.jpg",
      "https://i.imgur.com/ZFNTIKP.jpg",
      "https://i.imgur.com/oF7MPa4.jpg",
     "https://i.imgur.com/vwr7JWA.gif",
      "https://i.imgur.com/SSt3QBk.jpg",
      "https://i.imgur.com/mYIXtrA.jpg",
      
      "https://i.imgur.com/cOpSjV5.jpg",

      "https://i.imgur.com/Q3eLY0U.gif",
      "https://i.imgur.com/3ItccKq.jpg",
      "https://i.imgur.com/Y22xgVE.jpg",
      "https://i.imgur.com/SF4HoNe.jpg",
      "https://i.imgur.com/epBQuiA.jpg",
      "https://i.imgur.com/HfTqyD7.jpg",
      "https://i.imgur.com/aGLsoGo.jpg",
      "https://i.imgur.com/6HKeLhW.jpg",
      "https://i.imgur.com/cOpSjV5.jpg"
    ],

    playList: [
      {
        previewImage: "https://i.imgur.com/H9ArzdY.jpg",
        id:"fSz-VrZykhc",
        youtubeUrl: (
          <iframe
            width="1584"
            height="620"
            src="https://www.youtube.com/embed/fSz-VrZykhc"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        )
      },
      {
        previewImage: "https://i.imgur.com/YDRqD2u.jpg",
        id:"fSz-VrZykhc",

        youtubeUrl: (
          <iframe
            width="1584"
            height="620"
            src="https://www.youtube.com/embed/mkrhArBsgQU"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        )
      },

      {
        previewImage: "https://i.imgur.com/aVDAbby.jpg",
        id:"fSz-VrZykhc",

        youtubeUrl: (
          <iframe
            width="1584"
            height="620"
            src="https://www.youtube.com/embed/0WNda8PxZRo"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        )
      },
      {
        previewImage: "https://i.imgur.com/2gUpYMP.jpg",
        id:"fSz-VrZykhc",

        youtubeUrl: (
          <iframe
            width="1584"
            height="620"
            src="https://www.youtube.com/embed/e9QwEoZAqMU"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        )
      },
      {
        previewImage: "https://i.imgur.com/n6VYdxY.jpg",
        id:"fSz-VrZykhc",

        youtubeUrl: (
          <iframe
            width="1584"
            height="620"
            src="https://www.youtube.com/embed/1wGHGpJ6vuw"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        )
      }
    ],
    secondGallery: [
      "https://i.imgur.com/G4tyR2V.jpg",
      "https://i.imgur.com/MkU7tBQ.jpg",
      "https://i.imgur.com/OOErZxh.jpg",

      "https://i.imgur.com/ILjONDU.jpg",
      "",
      "https://i.imgur.com/jnxcZxH.jpg",
      "https://i.imgur.com/FbKcacV.jpg",
      "https://i.imgur.com/rEERyJ3.jpg",
      "https://i.imgur.com/B2mvWdN.jpg"
      //   "https://i.imgur.com/6n2bOVI.jpg",
      //  "https://i.imgur.com/IN59U0I.jpg"
    ],
    graficasCarrousel: [
      "https://i.imgur.com/ZgJ017X.jpg",
      "https://i.imgur.com/pjAzuHQ.jpg",
      "https://i.imgur.com/HOies69.jpg",
      "https://i.imgur.com/YRg1OaV.jpg",
      "https://i.imgur.com/5njLcsR.jpg",
      "https://i.imgur.com/0ofiChm.jpg"
    ],
    graficasCarrouselBottom: [
      "https://i.imgur.com/IN59U0I.jpg",
      "https://i.imgur.com/6n2bOVI.jpg",
      "https://i.imgur.com/jnxcZxH.jpg",
      "https://i.imgur.com/rEERyJ3.jpg"
    ],
    radio: [
      "http://docs.google.com/uc?export=open&id=1Kp6EhzE2munUFG7h6AKBWr1G6X1OkQPK",
      "http://docs.google.com/uc?export=open&id=1czWBHOtC4zjDXcxswrwH5iFB3FqlKMPz",
      "http://docs.google.com/uc?export=open&id=1pYgfsvkHhtAVR97DsqcSrUSqXBEsCD_t",

      "http://docs.google.com/uc?export=open&id=1N3epMqvrszpa7U3zK8joHqz3TKW7dku8",
      
      "http://docs.google.com/uc?export=open&id=1GidO-AerlCDvnbVCg0m2JrUbCaqKUy8J",
    ],
    itemData: [

    ]


    
    
  },





  other: {
    title: `Campaña institucional “Salud”`,
    subTItle: " un gran desafío también desde lo creativo.",
    text: `Medifé es una empresa que trabaja sobre el concepto ampliado de salud. Es decir: piensa la salud en grande. Lo que hicimos nosotros fue comunicar eso, llevándolo a la conceptualización total. 
    Buscamos un mensaje claro y de alto impacto que refleje la salud como un modo de vida: tener tiempo para el bienestar, el ocio, los lazos sociales y el deporte, sin dejar afuera la salud mental. Desde lo visual, acudimos a un recurso disruptivo cuidando el alto valor estético aplicado en varias piezas audiovisuales de muy corta duración. Esto último resultó un gran desafío y estamos muy contentos con el resultado.`,
    text1: ``,
    carrousel:true,
    textMobile: `Medifé es una empresa que trabaja sobre el concepto ampliado de salud. Es decir: piensa la salud en grande. Lo que hicimos nosotros fue comunicar eso, llevándolo a la conceptualización total. Buscamos un mensaje claro y de alto impacto que refleje la salud como un modo de vida: tener tiempo para el bienestar, el ocio, los lazos sociales y el deporte, sin dejar afuera la salud mental. Desde lo visual, acudimos a un recurso disruptivo cuidando el alto valor estético aplicado en varias piezas audiovisuales de muy corta duración. Esto último resultó un gran desafío y estamos muy contentos con el resultado.`,
    client: "Medifé",
    ano: "Año 2022",
    campanha: `Salud`,
    marquer: ` GRÁFICA - RADIO - SPOTS - CAMPAÑA 360 - CREATIVIDAD -`,
    bannerImage: "https://i.imgur.com/TZqWHex.jpg",
    previewCardImage:"https://i.imgur.com/EzLEC9M.jpg",
    firstGallery: [
"https://i.imgur.com/jccjUyr.jpg",
"https://i.imgur.com/RygK0iO.jpg",
"https://i.imgur.com/BjOKVJ3.jpg",
"https://i.imgur.com/f5ek2jE.jpg",
"https://i.imgur.com/0wjoImK.jpg",

"https://i.imgur.com/fgaEYXo.jpg",
"https://i.imgur.com/HXpkOfY.jpg",
"https://i.imgur.com/fh2U2EK.jpg",
"https://i.imgur.com/gzsfOPw.jpg",
"https://i.imgur.com/Ej2u7Bx.jpg",

"https://i.imgur.com/Sjspchg.jpg",
"https://i.imgur.com/3LXaUH1.jpg",
"https://i.imgur.com/HSpv2U0.jpg",
"https://i.imgur.com/eEWk0SZ.jpg",
"https://i.imgur.com/uEO2hlt.jpg",


    ],

    playList: [
      {
        previewImage: "https://i.imgur.com/ZucldBW.jpg",
        id:"H_SIvMDRuxs",

        youtubeUrl: (
          <iframe
            width="1584"
            height="620"
            src="https://www.youtube.com/embed/HLffx6rGRBk"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        )
      },
   
      {
        previewImage: "https://i.imgur.com/HtAL6Pz.jpg",
        id:"H_SIvMDRuxs",

        youtubeUrl: (
          <iframe
            width="1584"
            height="620"
            src="https://www.youtube.com/embed/R0pdKcum6-0"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        )
      },
      {
        previewImage: "https://i.imgur.com/LQV2qJU.jpg",
        id:"H_SIvMDRuxs",

        youtubeUrl: (
          <iframe
            width="1584"
            height="620"
            src="https://www.youtube.com/embed/zAltn2ybNRQ"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        )
      },
      {
        previewImage: "https://i.imgur.com/3wiV7mX.jpg",
        id:"H_SIvMDRuxs",

        youtubeUrl: (
          <iframe
            width="1584"
            height="620"
            src="https://www.youtube.com/embed/j2bJmI4_69I"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        )
      },
      {
        previewImage: "https://i.imgur.com/1Cmn0ZZ.jpg",
        id:"H_SIvMDRuxs",

        youtubeUrl: (
          <iframe
            width="1584"
            height="620"
            src="https://www.youtube.com/embed/9PUfsfmt6W0"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        )
      }
    ],
    secondGallery: [],
    graficasCarrousel: [],
    graficasCarrouselBottom: [],
    radio: [
   
    ],
    itemData: []
  },

};


