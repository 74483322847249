import React from 'react';
function Footer() {

    return (
        <div className="footer pattern">
             <div style={{alignContent:"center",alignItems:"center",display:"flex"}}>
            <p className="m footer-text-web"><strong>Enero Comunicación · </strong>3 de febrero 1869 · Ciudad de Buenos Aires · Argentina · info@agenciaenero.com</p>
            <p  className="m footer-text-mobile"><strong>Enero Comunicación</strong> · info@agenciaenero.com<br></br>3 de febrero 1869 · CABA · Argentina </p>
           
            </div>
        </div>

    )
}

export default Footer;