import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import Fade from "react-reveal/Fade";
import HeaderSubTitle from "../components/HeaderBackground";
import { Link } from "react-router-dom";
import { TodosProjetos } from "./../onlineMedia";

const settings = {
  dots: true,
  infinite: true,
  speed: 500,

  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  autoPlay: true
};
export default function Proyectos() {
  const [Projetos, setProjetos] = useState([]);
  const [Loading, setLoading] = useState(true);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async id => {
    let list = [];
    for (const key in TodosProjetos) {
      if (TodosProjetos.hasOwnProperty.call(TodosProjetos, key)) {
        var element = TodosProjetos[key];
        element.id = key;
        list.push(element);
      }
    }
    setProjetos(list);
    setLoading(false);
  };

  return (
    <div className="routeContainer">
      <HeaderSubTitle text={"PROYECTOS"} color={"#6BBEE8"}></HeaderSubTitle>
      <Fade>
        <FormSection data={Projetos}></FormSection>
      </Fade>
    </div>
  );
}

const FormSection = ({ data }) => {
  return (
    <div
      className="mb-4"
      style={{
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        display: "flex"
      }}
    >
      <ImagesProyectos data={data}></ImagesProyectos>
    </div>
  );
};
const ImagesProyectosCarrousel = ({ data }) => {
  return (
    <div className=" w-100">
   
      <div className="sliderProyectosWeb">
        <SliderWeb data={data}></SliderWeb>
      </div>
      <SliderMobileProyecto data={data}></SliderMobileProyecto>
    </div>
  );
};

const SliderMobileProyecto = ({ data }) => {

  return (

    <div style={{ marginTop: 40 }}>
      {data.map(e => {
        return <ProyectoCardMobile projeto={e}></ProyectoCardMobile>;
      })}
    </div>

  );
};

const SliderWeb = ({ data }) => {
  const settings = {
    dots: false,
    infinite: true,

    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    pauseOnHover: false,
    autoplayspeed: 1000
  };
  return (
    <Slider {...settings}>
      {data.map((e, i) => {
        return i % 2 == 0 ? (
          <>
            <ProyectoCardFix projeto={data[i]}></ProyectoCardFix>
            <ProyectoCardFix projeto={data[i + 1]}></ProyectoCardFix>
          </>
        ) : (
          ""
        );
      })}
    </Slider>
  );
};

const ProyectoCardFix = ({ projeto }) => {
  if (!projeto) return null;
  return (
    <div className="col-12 col-sm-6 col-md-4 col-lg-12 p-0  css-sm-bugfix">
      <Link
        className="card-per-reverse draw-border-blue"
        to={projeto.id=="medife"?"/medife":"/proyectoSingle/" + projeto.id}
        style={{
          width: "100%",

          backgroundImage: "url(" + projeto.previewCardImage + ")",

          "background-repeat": "no-repeat",
          "background-size": "cover",
          height: "25vw",
          justifyContent: "flex-start",
          display: "flex",
          borderRadius: 0,
          alignItems: "flex-start"
        }}
      >
        {/* <img src="assets/images/CAPA.jpg" width={"100%"}></img> */}

        <div
          className="bar-fad"
          style={{
            justifyContent: "flex-start",
            alignItems: "flex-start",
            display: "flex",
            flexDirection: "column",
            width: "100%"
          }}
        >
          <h3
            className="section-title-no-space hacemos-card-title"

            style={{ zIndex: 9999,textAlign:"left" }}
          >
            {projeto.title}
          </h3>
          <div
            className="blue-project-bar section-title-no-space"
            style={{
              zIndex: 9999,
              marginLeft: "10%",
              marginTop: "2%",
              marginBottom: "5%"
            }}
          ></div>

          <div
            className="hacemos-card-text section-title-no-space"
            style={{
              fontFamily: "Inria Sans",
              fontSize: "1rem",
              color: "white",
              zIndex: 9999
            }}
          >
            Spot / Gráfica
          </div>
        </div>
      </Link>
    </div>
  );
};

const ImagesProyectos = ({ data }) => {
  return (
    <div className=" w-100">
      <div className="hacemos-cards-container row w-100 p-0 m-0">
        {data.map(e => {
          return <ProyectoCard projeto={e}></ProyectoCard>;
        })}
      </div>
      <SliderMobileProyecto data={data}></SliderMobileProyecto>
    </div>
  );
};

const ProyectoCard = ({ projeto }) => {
  if (!projeto) return null;
  return (
    <div className="col-12 col-sm-6 col-md-4 col-lg-3 p-0  css-sm-bugfix  ">
      <Link
        className="card-per-reverse draw-border-blue  ratio ratio-1x1 css-sm-bugfix-2 "
        to={projeto.id=="medife"?"/medife":"/proyectoSingle/" + projeto.id}
        style={{
          width: "100%",

          backgroundImage: "url(" + projeto.previewCardImage + ")",

          "background-repeat": "no-repeat",
          "background-size": "cover",
          height: "25vw",
          justifyContent: "flex-start",
          display: "flex",
          borderRadius: 0,
          alignItems: "flex-start"
        }}
      >
        {/* <img src="assets/images/CAPA.jpg" width={"100%"}></img> */}

        <div
          className="bar-fad"
          style={{
            justifyContent: "flex-start",
            alignItems: "flex-start",
            display: "flex",
            flexDirection: "column",
            width: "100%"
          }}
        >
          <h3
            className="section-title-no-space hacemos-card-title"
            style={{ zIndex: 9999,textAlign:"left" }}
          >
            {projeto.title}
          </h3>
          <div
            className="blue-project-bar section-title-no-space"
            style={{
              zIndex: 9999,
              marginLeft: "10%",
              marginTop: "2%",
              marginBottom: "5%"
            }}
          ></div>

        { /* <div
            className="hacemos-card-text section-title-no-space"
            style={{
              fontFamily: "Inria Sans",
              fontSize: "1rem",
              color: "white",
              zIndex: 9999
            }}
          >
            {projeto.subTItle}
          </div>*/}
        </div>
      </Link>
    </div>
  );
};


const ProyectoCardMobile = ({ projeto }) => {
  if (!projeto) return null;
  return (
    <div style={{ width: "85%", margin: "auto", marginBottom: 5, marginTop: 5 }} className="   cardProyectoMobile">
      <Link
        className="card-per-reverse draw-border-blue"
        to={projeto.id == "medife" ? "/medife" : "/proyectoSingle/" + projeto.id}
        style={{
          width: "100%",

          backgroundImage: "url(" + projeto.bannerImage + ")",
          
          "background-repeat": "no-repeat",
          "background-size": "cover",

          justifyContent: "flex-start",
          display: "flex",
          borderRadius: 0,
          alignItems: "flex-start",
          height: "220px"
        }}
      >
        {/* <img src="assets/images/CAPA.jpg" width={"100%"}></img> */}

        <div
          style={{
            backgroundImage: "linear-gradient(to bottom, rgba(255,255,255,0), rgba(0,0,0,0.9))",
            height:"50%",
            alignSelf:"flex-end",
            backgroundPosition:"bottom",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            display: "flex",
            flexDirection: "column",
            width: "100%",
            paddingLeft: 20,
            paddingTop: 20
          }}
        >

          <div
            className="blue-project-bar section-title-no-space"
            style={{ zIndex: 9999, marginLeft: "10%" }}
          ></div>

          <h3 className="project-mobile-card-title" style={{ zIndex: 500 }}>
           <strong> {projeto.title}</strong> - {projeto.client}
          </h3>

        </div>
      </Link>
    </div>
  );
};
