import React, { useState } from "react";
import coverLogo from "./../../assets/main-logo.png";
import Slide from "react-reveal/Flip";
import Fade from "react-reveal/Fade";
import { useHistory } from "react-router";
import Delay from "../delay/delay";

function CoverLogo() {
  const [CanShow, setCanShow] = useState(true);
  const history = useHistory();

  var myScrollFunc = function () {
    var y = window.scrollY;
    if (y >= 200) {
      setCanShow(false);
    } else {
      setCanShow(true);
    }
  };

  React.useEffect(() => {
    window.addEventListener("scroll", myScrollFunc);

    const unlisten = history.listen(() => {
      setCanShow(false);
    });

    return () => {
      unlisten();
    };
  }, []);

  return (
    <div className="pattern cover">
      <div className="coverLogoContainer">
        <Slide right>
          <img className="coverLogo " alt="Agencia Enero" src={coverLogo}></img>
        </Slide>
      </div>
      <div className="icon-show">

      {/* <Delay d={900}>
      <Fade when={CanShow}>
        <div className="icon-scroll"></div>
      </Fade>
      </Delay> */}
      </div>

    </div>
  );
}

export default CoverLogo;
