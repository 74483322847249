import React, { useState } from "react";
import InstagramIcon from "@material-ui/icons/Instagram";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import { Link, useHistory } from "react-router-dom";
import Hamburger from "hamburger-react";
import Slide from "react-reveal/Slide";
import Fade from "react-reveal/Fade";
import Delay from "../components/delay/delay";
import coverLogo from "./../assets/main-logo-nocom-center.png";

function MainNav() {
  const [CanShow, setCanShow] = useState(
    window.location.pathname == "/" ? false : true
  );
  const history = useHistory();
  const [isOpen, setOpen] = useState(false);

  var myScrollFunc = function () {
    var y = window.scrollY;
    if (y >= 300) {
      setCanShow(true);
    } else {
      setCanShow(window.location.pathname == "/" ? false : true);
    }
  };
  React.useEffect(() => {
    window.addEventListener("scroll", myScrollFunc);

    const unlisten = history.listen(() => {
      setCanShow(window.location.pathname != "/");
    });
    return () => {
      unlisten();
    };
  }, []);
  React.useEffect(() => {
    window.addEventListener("scroll", myScrollFunc);
  }, []);
  return (
    <>
      <Fade top when={CanShow}>
        <div
          className={"nav-black-mobile"}
          style={{ maxHeight: 97, zIndex: 9999 }}
        >
          <img className="imgmob" alt="Agencia Enero" src={coverLogo}></img>

          <HambuerguerItems
            isOpen={isOpen}
            setOpen={setOpen}
          ></HambuerguerItems>
        </div>
      </Fade>

      <Fade top when={!CanShow}>
        <div
          className={"nav-black-mobile"}
          style={{ maxHeight: 97, zIndex: 99, backgroundColor: "transparent" }}
        >
          <HambuerguerItems
            isOpen={isOpen}
            setOpen={setOpen}
          ></HambuerguerItems>
        </div>
      </Fade>
    </>
  );
}

export default MainNav;

const HambuerguerItems = ({ isOpen, setOpen }) => {
  return (
    <>
      <Delay d={600} height={34}>
        <Slide right>
          <div className="hamburguer">
            <Hamburger color="white" toggled={isOpen} toggle={setOpen} />
          </div>
        </Slide>
      </Delay>

      {isOpen && (
        <div className="menu-mobile">
          <ul
            className="menu-list-mobile"
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              padding: 0
            }}
          >
            <div
              style={{
                height: 70,
                justifyContent: "space-between",
                alignItems: "center",
                display: "flex",
                flexDirection: "row",
                padding: 12
              }}
            >
                            <img
                alt="Agencia Enero"
                style={{ width: 90 }}
                src={coverLogo}
              ></img>
              <Hamburger color="white" toggled={isOpen} toggle={setOpen} />

            </div>
            <li className="mo" onClick={()=>setOpen(false)}>
              <Link
                className="men underline-menu-yel"
                style={{ color: "#F7D97C", textAlign: "left", color: "white" }}
                to="/"
              >
                HOME
              </Link>
            </li>

            <li className="mo" onClick={()=>setOpen(false)}>
              <Link
                className="men underline-menu-yel"
                style={{ color: "#F7D97C", textAlign: "left" }}
                to="/agencia"
              >
                AGENCIA
              </Link>
            </li>
            <li className="mo" onClick={()=>setOpen(false)}>
              <Link
                className=" men underline-menu-pink"
                style={{ color: "#E097B9" }}
                to="/hacemos-m"
              >
                HACEMOS
              </Link>
            </li>
            <li className="mo" onClick={()=>setOpen(false)}>
              <Link
                className="men underline-menu-blue"
                style={{ color: "#6ABFE9" }}
                to="/proyectos"
              >
                PROYECTOS
              </Link>
            </li>
            <li className="mo" onClick={()=>setOpen(false)}>
              <Link
                className=" men underline-menu-orange"
                style={{ color: "#E3903E" }}
                to="/contacto"
              >
                CONTACTO
              </Link>
            </li>
          </ul>
        </div>
      )}
    </>
  );
};
