import React, { useEffect, useState } from "react";
import ActDescription from "../components/ActDescription";
import HomeBody from "../components/HomeBody";
import CoverLogo from "../components/sliders/CoverLogo";
import Slide from "react-reveal/Slide";
import Fade from "react-reveal/Fade";
import { useLocation } from "react-router";
import HeaderSubTitle from "../components/HeaderBackground";
import Slider from "react-slick";
import PlayArrow from "@material-ui/icons/YouTube";

import CollectionsIcon from "@material-ui/icons/Collections";
import Radio from "@material-ui/icons/Radio";
import Modal from "@material-ui/core/Modal";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { TodosProjetos } from "../onlineMedia";
import AudioPlayer from "react-h5-audio-player";
import useWindowDimensions from "../utils/windowsDimensions";
import "react-h5-audio-player/lib/styles.css";

const settings = {
  dots: false,
  arrows: false,
  autoplay: true,
  outline: "none",
  infinite: true,
  autoplayspeed: 500
};
export default function ProyectoMedife() {
  const { pathname } = useLocation();
  const [Project, setProject] = useState({});
  const [project1Images, setProject1Images] = useState({});
  const [Loading, setLoading] = useState(true);
  const [openVideo, setOpenVideo] = useState(false);
  const [video, setVideo] = useState(0);
  const [openGaleryModal, setOpenGalleryModal] = useState(false);
  const [openSecondGaleryModal, setOpenSecondGalleryModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(0);
  const [radioModalOpen, setRadioModalOpen] = useState(false);
  const [radioSelected, setRadioSelected] = useState("");
  const { height, width } = useWindowDimensions();


  const itemData = [
    {
      rows: 2,
      cols: 1
    },
    {
      rows: 3,
      cols: 1
    },
    {
      rows: 3,
      cols: 1
    },
    {
      rows: 4,
      cols: 1
    }, //OK primera fila
    {
      rows: 2,
      cols: 1
    },
    {
      rows: 2,
      cols: 1
    },
    {
      rows: 2,
      cols: 1
    },
    {
      rows: 4,
      cols: 1
    }, //ok 2da fila
    {
      rows: 2,
      cols: 1
    },
    {
      rows: 2,
      cols: 1
    },
    {
      rows: 3,
      cols: 1
    },
    {
      rows: 4,
      cols: 1
    }, //ok cuarta fila
    {
      rows: 3,
      cols: 1
    },
    {
      rows: 2,
      cols: 1
    },
    {
      rows: 2,
      cols: 1
    }
  ]
  useEffect(() => {
    fetchData("medife");
  }, []);

  const srcset = (image, size, rows = 1, cols = 1) => {
    return {
      src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
      srcSet: `${image}?w=${size * cols}&h=${size * rows
        }&fit=crop&auto=format&dpr=2 2x`
    };
  };

  const fetchData = async id => {
    console.log(TodosProjetos, id, TodosProjetos[id]);
    if (TodosProjetos[id]) {
      setProject1Images(TodosProjetos[id]);
      setLoading(false);
    }
  };

  if (Loading) {
    return null;
  }
  return (
    <div className="routeContainer">

      <div className="row p-0 d-none d-md-block" >
        <Fade>
          <ImageMain img={project1Images.bannerImage}></ImageMain>
        </Fade>
      </div>
      <div className="row p-0 d-md-none" >
        <Fade>
          <ImageMainMobile img={project1Images.bannerImage}></ImageMainMobile>
        </Fade>
      </div>

      <div className="row p-0">
        <FullText
          text={project1Images.text}
          text1={project1Images.text1}
          client={project1Images.client}
          ano={project1Images.ano}
          campanha={project1Images.campanha}
        ></FullText>

        <FullTextMobile
          text={project1Images.textMobile}
          text1={project1Images.text1}
          client={project1Images.client}
          ano={project1Images.ano}
          campanha={project1Images.campanha}
        ></FullTextMobile>
      </div>

      {project1Images.carrousel ?
        <Carrousel images={project1Images.firstGallery} ></Carrousel>
        : <div className="row p-0" style={{ backgroundColor: "#231F20" }}>
          <ImageList
            style={{ padding: 20 }}
            variant="quilted"
            cols={width < 760 ? 2 : 4}
            rowHeight={200}
            gap={4}
          >

            {/* { itemData.map((item, index) => (
            index < project1Images.firstGallery.length ?
            <ImageListItem
              key={item.img}
              cols={item.cols}
              rows={width < 760 ? item.rows / 2 : item.rows}
            >
              <img
                {...srcset(
                  project1Images.firstGallery[index],
                  121,
                  item.rows,
                  item.cols
                )}
                alt={item.title}
                className="imageHover"
                loading="lazy"
                onClick={() => {
                  setSelectedImage(index);
                  setOpenGalleryModal(true);
                }}
              />
            </ImageListItem> : <></>
          ))} */}
          </ImageList>
        </div>

      }

      {/*CARROUSEL GALERIA*/}



      <Fade>



        <div style={{ backgroundColor: "#231F20" }}>


          <iframe width="100%" src={"https://www.youtube.com/embed/XRL1D1kS-sg"} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen className="css-sm-bugfix-2-allways"></iframe>


        </div>

      </Fade>

      <div className="row p-0">
        <Slide style={{ backgroundColor: "#231F20" }}>
          <div
            className="marquee-container"
          >
            <div class="wrapper">
              <div class="marquee">
                {[0, 0, 0, 0, 0, 0, 0, 0, 0].map(e => { return `${project1Images.marquer}` })}

              </div>
            </div>
          </div>
        </Slide>
      </div>


      <Fade>



        <div style={{ backgroundColor: "#231F20" }}>


          <iframe width="100%" src={"https://www.youtube.com/embed/fSz9ohTgn4U"} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen className="css-sm-bugfix-2-allways"></iframe>


        </div>

      </Fade>
      {project1Images.playList.length > 0 && <div className="row p-0">
        <Slide >
          <div
            className="marquee-container"
          >

            < div class="wrapper">
              <div class="marquee">
                {[0, 0, 0, 0, 0, 0, 0, 0, 0].map(e => { return `${project1Images.marquer}` })}
              </div>
            </div>
          </div>
        </Slide>
      </div >}

      <Carrousel images={project1Images.firstGallery} ></Carrousel>


      <div style={{ backgroundColor: "#231F20" }}>


        <iframe width="100%" src={"https://www.youtube.com/embed/" + project1Images.playList[0].id} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen className="css-sm-bugfix-2-allways"></iframe>

        {project1Images.radio.map((e, i) =>
          <div
            style={{
              height: "15vh",
              alignContent: "center",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              justifyItems: "center",
              backgroundColor: "#231F20",
            }}
          >
            <Radio
              className="hoverRadio"
              onClick={(e) => { setRadioModalOpen(true); setRadioSelected(e) }}
              style={{
                cursor: "pointer",
                marginTop: 20,
                marginBottom: 40,
                fontSize: "4rem",
                color: "white",
                zIndex: 0
              }}
            ></Radio>
          </div>)}
      </div>


      {project1Images.playList.length > 0 && <div className="row p-0">
        <Slide >
          <div
            className="marquee-container"
          >

            < div class="wrapper">
              <div class="marquee">
                {[0, 0, 0, 0, 0, 0, 0, 0, 0].map(e => { return `${project1Images.marquer}` })}
              </div>
            </div>
          </div>
        </Slide>
      </div >}






      <Fade>



        <div style={{ backgroundColor: "#231F20" }}>


          <iframe width="100%" src={"https://www.youtube.com/embed/HDbPdlIUab0"} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen className="css-sm-bugfix-2-allways"></iframe>


        </div>

      </Fade>












      <div className="row p-0" style={{ backgroundColor: "#231F20" }}>
        <div
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: 50,
            marginBottom: 0,
            width: "97%"
          }}
        >
          <Slider className="imagesAgencia" {...settings}>
            {project1Images.graficasCarrousel.map(e => {
              return <AgenciaCard text={e}></AgenciaCard>;
            })}
          </Slider>
        </div>

        <div style={{ marginLeft: "auto", marginRight: "auto", width: "97%" }}>
          <Slider
            className="imagesAgencia"
            autoplay={true}
            slidesToShow={3}
            arrows={false}
            dots={false}
            outline="none"
            autoplayspeed={500}
            infinite={true}
          >
            {project1Images.graficasCarrouselBottom.map(e => {
              return <AgenciaCardLessWidth text={e}></AgenciaCardLessWidth>;
            })}
          </Slider>
          <Slider
            className="imagesAgenciaMobile"
            style={{ marginBottom: "30px" }}
            {...settings}
          >
            {project1Images.graficasCarrouselBottom.map(e => {
              return <AgenciaCard text={e}></AgenciaCard>;
            })}
          </Slider>
        </div>
      </div>
      <Modal
        open={openVideo}
        onClose={() => setOpenVideo(false)}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: 200
        }}
      >
        {openVideo !== false && (
          <div
            style={{
              outline: "none",
              display: "flex",
              width: "50%",
              height: "50%",
              justifyContent: "center",
              alignContent: "center"
            }}
          >

            {video.youtubeUrl}
          </div>
        )}
      </Modal>

      <Modal
        open={openGaleryModal}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          justifyItems: "center",
          alignContent: "center",
          opacity: "100%",
          justifySelf: "center",
          alignSelf: "center"
        }}
        onClose={() => setOpenGalleryModal(false)}
      >
        {width < 760 ? (
          <Slider
            {...settings}
            initialSlide={selectedImage}
            style={{
              marginTop: 50,
              width: "70%",
              justifyItems: "center",
              alignItems: "center",
              alignContent: "center",
              justifyContent: "center"
            }}
          >
            {project1Images.firstGallery.map((e, index) => (
              <img
                src={e}
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  objectFit: "center"
                }}
              ></img>
            ))}
          </Slider>
        ) : (
          <Slider
            {...settings}
            initialSlide={selectedImage}
            style={{
              marginTop: 50,
              width: "70%",
              height: "80vh",
              justifyItems: "center",
              alignItems: "center",
              alignContent: "center",
              justifyContent: "center"
            }}
          >
            {project1Images.firstGallery.map((e, index) => (
              <div

              >
                {
                  <img
                    src={e}

                    style={{ objectFit: "fill", marginLeft: "auto", marginRight: "auto", maxWidth: "100%", maxHeight: "85vh" }}
                  ></img>
                }
              </div>
            ))}
          </Slider>
        )}
      </Modal>

      <Modal
        open={openSecondGaleryModal}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          justifyItems: "center",
          alignContent: "center",
          opacity: "100%",
          justifySelf: "center",
          alignSelf: "center"
        }}
        onClose={() => setOpenSecondGalleryModal(false)}
      >
        <Slider
          {...settings}
          initialSlide={selectedImage}
          style={{
            outline: "none",
            marginTop: 50,
            width: "90%",
            height: "80vh",
            justifyItems: "center",
            alignItems: "center",
            alignContent: "center",
            justifyContent: "center"
          }}
        >
          {project1Images.secondGallery.map(
            (e, index) =>
              e != "" && (
                <div
                  style={{
                    outline: "none",
                    backgroundColor: "red",
                    height: "100px",
                    width: "500px",
                    alignItems: "center",
                    alignContent: "center"
                  }}
                >
                  {
                    <img
                      src={e}
                      style={{ marginLeft: "auto", marginRight: "auto" }}
                    ></img>
                  }
                </div>
              )
          )}
        </Slider>
      </Modal>

      <Modal
        open={radioModalOpen}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          justifyItems: "center",
          alignContent: "center",
          opacity: "100%",
          justifySelf: "center",
          alignSelf: "center"
        }}
        onClose={() => setRadioModalOpen(false)}
      >
        <div style={{ width: "50%" }}>


          <AudioPlayer
            src={radioSelected}
            autoPlay={true}
            autoPlayAfterSrcChange={true}
          // Try other props!
          />
        </div>
      </Modal>
    </div >
  );
}

const AgenciaCard = ({ text }) => {
  return (
    <div className="col-12 col-md-12 col-lg-12 p-0" style={{ width: "100%" }}>
      <div
        style={{
          opacity: 1,

          backgroundPosition: "center",

          justifyContent: "center",
          display: "flex",
          borderRadius: 0,
          alignItems: "center",
          width: "100%",
          backgroundSize: "cover"
        }}
      >
        {<img src={text} width={"100%"}></img>}
      </div>
    </div>
  );
};

const AgenciaCardLessWidth = ({ text }) => {
  return (
    <div className="col-12 col-md-12 col-lg-12 p-0" style={{ width: "100%" }}>
      <div
        style={{
          opacity: 1,

          backgroundPosition: "center",
          marginTop: 5,
          justifyContent: "center",
          display: "flex",
          borderRadius: 0,
          alignItems: "center",
          marginLeft: 2,
          marginRight: 2,
          backgroundSize: "cover",
          marginBottom: 20
        }}
      >
        {<img src={text} style={{ alignSelf: "center" }} width={"100%"}></img>}
      </div>
    </div>
  );
};

const ImageMain = ({ img }) => {
  return (
    <div className="col-12 p-0 ">
      <div
        style={{
          width: "100%",
          backgroundImage: "url(" + img + ")",
          opacity: 1,
          "background-repeat": "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "88vh",
          marginTop: "11vh",
          justifyContent: "center",
          display: "flex",
          borderRadius: 0,
          alignItems: "center",

        }}
      ></div>
    </div>
  );
};

const ImageMainMobile = ({ img }) => {
  return (
    <div className="col-12 p-0 " style={{ marginTop: "85px", }}>
      <div
        style={{
          width: "100%",
          backgroundImage: "url(" + img + ")",
          opacity: 1,
          "background-repeat": "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "30vh",

          justifyContent: "center",
          display: "flex",
          borderRadius: 0,
          alignItems: "center",

        }}
      ></div>
    </div>
  );
};



const FullText = ({ text, text1, client, ano, campanha }) => {
  return (
    <div
      className="col-12 col-md-12 col-lg-12 p-0 col-sm-12"
      style={{
        justifyContent: "center",
        display: "flex",
        borderRadius: 0,
        alignContent: "center",
        backgroundColor: "#231F20",
        flexDirection: "column"
      }}
    >
      <div className="presentation-single-text">
        <p style={{ fontWeight: "bold", marginBottom: 20, marginTop: 100 }}>{campanha}</p>
      </div>
      <div className="presentation-single-text">
        <p>{text}</p>
      </div>
      <div className="presentation-single-text">
        <p>{text1}</p>
      </div>
      <div className="presentation-single-text">
        <p style={{ fontWeight: "bold" }}>CLIENTE: {client}</p>
      </div>
      <div className="presentation-single-text">
        <p style={{ fontWeight: "bold", marginBottom: 100 }}>{ano}</p>
      </div>
    </div>
  );
};

const FullTextMobile = ({ text, text1, client, ano, campanha }) => {
  return (
    <div
      className="col-12 col-md-12 col-lg-12 p-0 col-sm-12"
      style={{
        justifyContent: "center",
        display: "flex",
        borderRadius: 0,
        alignContent: "center",
        backgroundColor: "#231F20",
        flexDirection: "column"
      }}
    >
      <div className="presentation-single-text-mobile">
        <p style={{ fontWeight: 800, marginBottom: 0, marginTop: 50 }}>{campanha}</p>
      </div>
      <div
        className="presentation-single-text-mobile"
        style={{ paddingTop: 20 }}
      >
        <p>{text}</p>
      </div>
      <div
        className="presentation-single-text-mobile"
        style={{ paddingBottom: 15 }}
      >
        <p>{text1}</p>
      </div>
      <div className="presentation-single-text-mobile">
        <p style={{ fontWeight: "bold" }}>CLIENTE: {client}</p>
      </div>
      <div className="presentation-single-text-mobile">
        <p style={{ fontWeight: "bold", marginBottom: 50 }}>{ano}</p>
      </div>
    </div>
  );
};

const Carrousel = ({ images }) => {
  const settings = {
    autoplay: true,
    pauseOnHover: false,
    autoplayspeed: 800
  };
  return (
    <div style={{ width: "100%" }}>
      <Slider className="imagesAgencia" {...settings}>
        {images.map(e => {
          return <CarrouselCard source={e}></CarrouselCard>;
        })}
      </Slider>
      <Slider className="imagesAgenciaMobile" {...settings}>
        {images.map(e => {
          return <CarrouselCard source={e}></CarrouselCard>;
        })}
      </Slider>
    </div>
  );
};


const CarrouselCard = ({ source }) => {
  return (
    <div className="col-12 col-md-12 col-lg-12 p-0" style={{ width: "100%" }}>
      <div
        style={{
          opacity: 1,

          backgroundPosition: "center",
          height: 650,
          justifyContent: "center",
          display: "flex",
          borderRadius: 0,
          alignItems: "center",
          width: "100%",
          backgroundSize: "cover",
          backgroundColor: "#231F20"
        }}
      >
        {<img style={{ objectFit: "contain", backgroundColor: "#231F20", maxHeight: "100%" }} src={source} width={"100%"}></img>}
      </div>
    </div>
  );
};