import React, { useState } from "react";
import InstagramIcon from "@material-ui/icons/Instagram";
import LinkedInIcon from "@material-ui/icons/LinkedIn";

import MailIcon from "@material-ui/icons/MailOutline";
import { Link, useHistory } from "react-router-dom";
import Hamburger from "hamburger-react";
import Slide from "react-reveal/Fade";
import Fade from "react-reveal/Fade";
import coverLogo from "./../assets/main-logo-nocom-center.png";
import HeadShake from "react-reveal/HeadShake";
import Delay from "./delay/delay";

function MainNav() {
  const [isOpen, setOpen] = useState(false);
  const [CanShow, setCanShow] = useState(
    window.location.pathname == "/" ? false : true
  );
  const history = useHistory();

  var myScrollFunc = function () {
    var y = window.scrollY;
    if (y >= 200) {
      setCanShow(true);
    } else {
      setCanShow(window.location.pathname == "/" ? false : true);
    }
  };

  React.useEffect(() => {
    window.addEventListener("scroll", myScrollFunc);

    const unlisten = history.listen(() => {
      setCanShow(window.location.pathname != "/");
    });
    return () => {
      unlisten();
    };
  }, []);

  return (
    <>
      <Fade top when={CanShow}>
        <div className={"nav-black"} style={{zIndex:99999}}>
          <img
            className="imgmobmain"
            alt="Agencia Enero"
            style={{ zIndex: 1100,cursor:"pointer"}}
            onClick={() => history.push("/")}
            src={coverLogo}
          
          ></img>

          <HeaderMainContent></HeaderMainContent>

          {/*isOpen&&*/}
        </div>
      </Fade>

      <Fade top when={!CanShow}>
        {!CanShow && <div className={"nav-black"} style={{zIndex:99999, backgroundColor: "transparent" }}>
          <HeaderMainContent></HeaderMainContent>
        </div>}
      </Fade>
    </>
  );
}

export default MainNav;

const HeaderMainContent = () => {
  return (
    <>
      <div className={"nav-list-container"} >
        <Slide right cascade>
          <ul className={"nav-list"}>
            <li className="men">
              <Link
                className=" underline-menu-yel"
                style={{ color: "#F7D97C" }}
                to="/agencia"
              >
                AGENCIA
              </Link>
            </li>
            <li className="men">
              <Link
                className="  underline-menu-pink"
                style={{ color: "#E097B9" }}
                to="/hacemos"
              >
                HACEMOS
              </Link>
            </li>
            <li className="men">
              <Link
                className=" underline-menu-blue"
                style={{ color: "#6ABFE9" }}
                to="/proyectos"
              >
                PROYECTOS
              </Link>
            </li>
            <li className="men">
              <Link
                className="  underline-menu-orange"
                style={{ color: "#E3903E" }}
                to="/contacto"
              >
                CONTACTO
              </Link>
            </li>
          </ul>
        </Slide>
      </div>

      <HeadShake>
        <div className={"nav-social-icons"}>
          <a target="_blank" href="https://www.instagram.com/agenciaenero/">
            <InstagramIcon style={{ color: "#F1F0EF", marginRight: "10px" }} />
          </a>
          <MailIcon onClick={() =>
            window.location.href = "mailto:info@agenciaenero.com"
          } style={{cursor:"pointer", color: "#F1F0EF", marginRight: "100px" }} />
        </div>
      </HeadShake>
    </>
  );
};
