import React from "react";
import { Switch, Route } from "react-router-dom";
import Contacto from "./contacto";
import Proyectos from "./proyectos";
import Agencia from "./agencia";
import Home from "./home";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { useLocation } from "react-router-dom";
import ProyectoSingle from "./proyecto_single";
import DeepLinkStock from "./deep_link";
import ProyectoMedife from "./proyecto_medife";
import Hacemos from './hacemos';
export default function SwitchPages() {
  const location = useLocation();
  return (
    <TransitionGroup>
      <CSSTransition timeout={300} classNames="fade" key={location.key}>
        <Switch location={location}>
          <Route path="/agencia">
            <Agencia />
          </Route>
          <Route path="/contacto">
            <Contacto></Contacto>
          </Route>
          <Route path="/proyectos">
            <Proyectos></Proyectos>
          </Route>

          <Route path="/proyectoSingle/:id">
            <ProyectoSingle />
          </Route>
          <Route path="/medife">
            <ProyectoMedife />
          </Route>
          <Route path="/hacemos-m">
            <Hacemos />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  );
}
